'use client'
import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { useAtom } from 'jotai'

import { AdminLayoutNext } from '@docpace/admin-react-components/AdminLayoutNext'
import { apolloClient, serverHostname } from '@docpace/apps/admin-app/client'
import { ctxPracticeIdAtom } from '@docpace/shared-react-atoms'

import '@docpace/apps/admin-app/tailwind.css'
import '@docpace/shared-react-styles/shared/globals.css'
import '@docpace/shared-react-styles/shared/materialUI.css'
import '@docpace/shared-react-styles/shared/cards.css'
import '@docpace/shared-react-styles/shared/tables.css'
import '@docpace/shared-react-styles/shared/layout.css'
import 'react-datetime/css/react-datetime.css'
import { useIsChangingRoute } from '@docpace/shared-react-hooks/useIsChangingRouteNext'
import { useNextQueryParamNext } from '@docpace/shared-react-hooks/useNextQueryParamNext'
import { useSessionSharing } from '@docpace/shared-react-hooks/useSessionSharing'

export default function AdminPracticeLayout({
    // Layouts must accept a children prop.
    // This will be populated with nested layouts or pages
    children,
  }: {
    children: React.ReactNode
  }) {
    useIsChangingRoute()
    const { isInitialized } = useSessionSharing()
    const practiceId = useNextQueryParamNext('practiceId')
    const [ , setContextPracticeId ] = useAtom(ctxPracticeIdAtom)
    useEffect(()=>{
        setContextPracticeId(practiceId)
    }, [ practiceId ])

    // const { Component, pageProps } = appProps

    return (
      <html lang="en"className="bg-docpace-bg dark:bg-docpace-bg-dark">
        <body>
        <ApolloProvider client={apolloClient}>
            <SnackbarProvider
                maxSnack={8}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Head>
                    <title>DOCPACE® Admin</title>
                    <meta name="viewport" content="initial-scale=1.0" />
                </Head>
                <main className="app">
                    { isInitialized && <AdminLayoutNext>
                        {children}
                    </AdminLayoutNext> }
                </main>
            </SnackbarProvider>
        </ApolloProvider>
        
        </body>
      </html>
    )
  }